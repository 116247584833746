<template>
  <div style="height: 800px; width: auto" v-html="poHtmlCode"/>
</template>

<script>
const axios = require("axios");
export default {
  name: "Word",
  data() {
    return {
      message: " ",
      poHtmlCode: "",
      subject: "",
      userName: "",
      fileName: "",
      QRCodeUrl: "",
      formId: "",
      isPre: "",
      id: "",
    };
  },
  created: async function () {
    const extraData = window.external.UserParams
        ? JSON.parse(window.external.UserParams)
        : {};

    this.formId = extraData.formId;
    this.isPre = extraData.isPre;
    this.id = extraData.id;

    try {
      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/audit`,
        method: "post",
        data: {
          ...this.$decodeObject(extraData)
        },
      })
          .then((res) => {
            this.poHtmlCode = res.data.msg;
          })
          .catch(function (err) {
            this.poHtmlCode = JSON.stringify(err);
          });
    } catch (error) {
      this.poHtmlCode = JSON.stringify(error);
    }

    const userInfoRes = await axios({
      url: `${this.baseUrl}/xinyuan/user/userId/${extraData.userId}`,
      method: "get",
    });

    this.userName = userInfoRes.data.data.name;
  },
  methods: {
    //控件中的一些常用方法都在这里调用，比如保存，打印等等
    Save() {
      pageofficectrl.WebSave();
    },
    SaveAsPDF() {
      pageofficectrl.WebSaveAsPDF();
      alert("另存为PDF成功");
    },
    // InsertSeal() {
    //   pageofficectrl.ZoomSeal.AddSeal(this.userName);
    // },
    Audit() {
      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/${this.id}`,
        method: "get",
      }).then(({data: {data: {qrCodeUrl}}}) => {
        pageofficectrl.word.SelectBookmark("PO_二维码");
        pageofficectrl.word.InsertInlineWebImage(qrCodeUrl);
      });
    },
    async reset() {
      const {data} = await axios({
        url: `${this.baseUrl}/xinyuan/evaluation/cancellation`,
        data: {
          type: this.isPre,
          formId: this.formId
        },
        method: "post",
      });

      if (data.code != 200) {
        alert(data.msg);
      } else {
        alert("撤销成功");
      }
    }
  },
  mounted() {
    // 将vue中的方法赋值给window
    window.Save = this.Save;
    // window.InsertSeal = this.InsertSeal;
    window.SaveAsPDF = this.SaveAsPDF;
    window.audit = this.Audit;
    window.reset = this.reset;
    window.AfterDocumentOpened = () => {
      pageofficectrl.ShowRevisions = false;
      pageofficectrl.FullScreen = true;

      window.audit();

      window.showRevisions = () => {
        pageofficectrl.ShowRevisions = !pageofficectrl.ShowRevisions;
      };
    }
  },
};
</script>
