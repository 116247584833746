<template>
  <div style="height: 800px; width: auto" v-html="poHtmlCode" />
</template>

<script>
const axios = require("axios");
export default {
  name: "Word",
  data() {
    return {
      message: " ",
      poHtmlCode: "",
      subject: "",
      id: "",
      userId: "",
      userName: "",
      fileName: "",
    };
  },
  created: async function () {
    try {
      const extraData = window.external.UserParams
        ? JSON.parse(window.external.UserParams)
        : {};
      console.log(extraData)

      this.id = extraData.id;
      this.userId = extraData.userId;

      const token = decodeURIComponent(extraData.token);

      const userInfoRes = await axios({
        url: `${this.baseUrl}/xinyuan/user/userId/${this.userId}`,
        method: "get",
        headers: {
          Authorization: token,
        },
      });

      const payload = {
        ...this.$decodeObject(extraData),
      };

      this.userName = userInfoRes.data.data.name;

      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/make`,
        method: "post",
        headers: {
          Authorization: token,
        },
        data: payload,
      })
        .then(({ data }) => {
          this.poHtmlCode = data.msg;
        })
        .catch(function (err) {
          this.poHtmlCode = JSON.stringify(err);
        });
    } catch (error) {
      alert(JSON.stringify(error));
      this.poHtmlCode = JSON.stringify(error);
    }
  },
  methods: {
    Save() {
      pageofficectrl.WebSave();
    },
    InsertSeal() {
      pageofficectrl.word.AddSeal();
    },
    SaveAsPDF() {
      pageofficectrl.WebSaveAsPDF();
      alert("另存为PDF成功");
    },
  },
  mounted() {
    window.Save = this.Save;
    window.InsertSeal = this.InsertSeal;
    window.SaveAsPDF = this.SaveAsPDF;
    this.$addShowRevisionButton();
  },
};
</script>
