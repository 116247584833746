import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/make',
    component: () => import("../views/make.vue")
  },
  {
    path: '/temp',
    component: () => import("../views/temp.vue")
  },
  {
    path: '/readOnly',
    component: () => import("../views/readOnly.vue")
  },
  {
    path: '/edit',
    component: () => import("../views/edit.vue")
  },
  {
    path: '/audit',
    component: () => import("../views/audit.vue")
  },
  {
    path: '/stamp',
    component: () => import("../views/stamp.vue")
  },
  {
    path: '/stampPdf',
    component: () => import("../views/stampPdf.vue")
  },
  {
    path: '/stampPdfOnly',
    component: () => import("../views/stampPdfOnly.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
