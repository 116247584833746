<template>
  <div style="height: 800px; width: auto" v-html="poHtmlCode" />
</template>

<script>
const axios = require("axios");
export default {
  name: "Word",
  data() {
    return {
      message: " ",
      poHtmlCode: "",
      subject: "",
      userName: "",
      fileName: "",
      QRCodeUrl: "https://xinyuan.qilushop.cn/api/profile/empty/empty.jpg",
    };
  },
  created: async function () {
    const extraData = window.external.UserParams
      ? JSON.parse(window.external.UserParams)
      : {};
    try {
      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/edit`,
        method: "post",
        data: {
          ...this.$decodeObject(extraData)
        },
      })
        .then((res) => {
          this.poHtmlCode = res.data.msg;
        })
        .catch(function (err) {
          this.poHtmlCode = JSON.stringify(err);
        });
    } catch (error) {
      this.poHtmlCode = JSON.stringify(error);
    }

    const userInfoRes = await axios({
      url: `${this.baseUrl}/xinyuan/user/userId/${obj.userId}`,
      method: "get",
    });

    this.userName = userInfoRes.data.data.name;

    // axios({
    //   url: `https://server.moye99.com:7000/api/revision/${obj.id}`,
    //   method: "get",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // }).then(({ data }) => {
    //   this.QRCodeUrl = data.data.qrCodeUrl;
    // });
  },
  methods: {
    //控件中的一些常用方法都在这里调用，比如保存，打印等等
    Save() {
      pageofficectrl.WebSave();
    },
    SaveAsPDF() {
      pageofficectrl.WebSaveAsPDF();
      alert("另存为PDF成功");
    },
    // InsertSeal() {
    //   pageofficectrl.ZoomSeal.AddSeal(this.userName);
    // },
    // Audit() {
    //   pageofficectrl.InsertWebImage(this.QRCodeUrl);
    // },
  },
  mounted() {
    // 将vue中的方法赋值给window
    window.Save = this.Save;
    // window.SaveAsPDF = pageofficectrl.WebSaveAsPDF;
    window.SaveAsPDF = this.SaveAsPDF;
    window.AfterDocumentOpened = () => {
      pageofficectrl.ShowRevisions = false;
      pageofficectrl.FullScreen = true;

      pageofficectrl.word.SelectBookmark("PO_二维码");
      pageofficectrl.word.InsertInlineWebImage("https://xinyuan.qilushop.cn/api/profile/empty/empty.jpg");

      window.showRevisions = () => {
        pageofficectrl.ShowRevisions = !pageofficectrl.ShowRevisions;
      };
    }
  },
};
</script>
